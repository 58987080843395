













import api from "@/api";
import { Vue, Component } from "vue-property-decorator";

@Component
export default class InCountryPerNationalityChart extends Vue {
  private chartOptions = {
    series: [] as number[],
    labels: [] as string[],
    colors: [] as string[],
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              showAlways: true,
              label: "Total"
            }
          }
        }
      }
    },
    chart: {
      type: "donut"
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: "bottom"
          }
        }
      }
    ]
  };

  private series = [0, 0];

  private labels: string[] = [];

  private async created() {
    const response = await api.CrewMemberService.apiCrewmemberInCountryNationalitySkilledWorkersGet();
    if (response.data.data) {
      this.labels = response.data.categories!;

      this.chartOptions.colors = this.labels
        .map(label => {
          if (label === "Ghanaian") {
            return "#008FFB";
          } else if (label === "Indian") {
            return "#00E396";
          } else if (label === "Philippine, Filipino") {
            return "#FEB019";
          } else if (label === "Sri Lankan") {
            return "#FF4560";
          } else if (label === "Indonesian") {
            return "#9966FF";
          } else if (label === "Latvian") {
            return "#CD7F32";
          }
        })
        .filter(color => color !== undefined) as string[];

      const options = { ...this.chartOptions };
      options.labels = this.labels;
      this.chartOptions = options;
      this.chartOptions.series = response.data.data;
    }
  }
}
