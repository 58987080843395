







import api from "@/api";
import { Vue, Component } from "vue-property-decorator";

@Component
export default class CrewMembersInCountryChart extends Vue {
  private chartOptions = {
    series: [] as number[],
    labels: ["In Country", "Out of Country"],
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              showAlways: true,
              label: "Total"
            }
          }
        }
      }
    },
    chart: {
      type: "donut"
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: "bottom"
          }
        }
      }
    ]
  };

  private async created() {
    const response = await api.CrewMemberService.apiCrewmemberInCountrySkilledWorkersGet();
    if (response.data.data) {
      this.chartOptions.series = response.data.data;

      const options = { ...this.chartOptions };
      this.chartOptions = options;
      this.chartOptions.series = response.data.data;
    }
  }
}
