


















import { Vue, Component } from "vue-property-decorator";
import SkilledWorkersInCountryChart from "@/components/charts/CrewMemberSkilledWorkersInCountryChart.vue";
import SkilledWorkersInCountryPerNationalityChart from "@/components/charts/SkilledWorkersInCountryPerNationalityChart.vue";
import SkilledWorkersOutCountryPerNationalityChart from "@/components/charts/SkilledWorkersOutCountryPerNationalityChart.vue";

@Component({
  components: {
    SkilledWorkersInCountryChart,
    SkilledWorkersInCountryPerNationalityChart,
    SkilledWorkersOutCountryPerNationalityChart
  }
})
export default class SkilledWorkerAnalytics extends Vue {}
